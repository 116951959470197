.rdp-root {
  --rdp-accent-color: black;
  --rdp-accent-background-color: #ff6326;
  --rdp-day_button-height: 36px;
  --rdp-day_button-width: 36px;
  --rdp-day-height: 38px;
  --rdp-day-width: 38px;
  --rdp-range_middle-color: white;
  --rdp-range_start-date-background-color: #ff6326;
  --rdp-range_end-date-background-color: #ff6326;
  --rdp-selected-border: solid #ff6326;
}
