@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    height: 100%;
    overflow: auto;
}

html {
    overscroll-behavior: none;
}

.openseadragon-canvas {
    outline: none;
}

video::-webkit-media-controls-mute-button {
    display: none;
}

video::-webkit-media-controls-volume-slider {
    display: none;
}

.rdrDefinedRangesWrapper {
    max-width: 0px;
}

.customSlider-track.customSlider-track-0 {
    /* color of the track before the thumb */
    background: #ea580c;
}

.customSlider-track.customSlider-track-1 {
    /* color of the loading track  */
    background: #dfa282;
}

.customSlider-track.customSlider-track-2 {
    /* color of the track */
    background: white;
    min-width: 0px;
}

.customSlider-thumb.customSlider-thumb-0 {
    /* the thumb */
    height: 1.5rem;
    width: 1.5rem;
}

.customSlider-thumb.customSlider-thumb-1 {
    /* loading thumb is not available */
    height: 0px;
    width: 0px;
}

.stackCustomSlider-track.stackCustomSlider-track-0 {
    /* color of the track before the thumb */
    background: white;
}

.stackCustomSlider-track.stackCustomSlider-track-1 {
    /* color of the track before the thumb */
    background: rgb(147 159 164);
}
